import { Flex, Heading, Text, Box } from '@chakra-ui/react'
import { ReactElement, useState } from 'react'

import { s3ImageURL } from '@miimosa/common'
import Icon from '@miimosa/design-system/components/Icon'

import Image from '@components/Image'

type Props = {
  steps: { number: number; imageUrl: string; text: ReactElement }[]
}

const HowItWorks = ({ steps }: Props) => {
  const [activeStep, setActiveStep] = useState(steps[0])
  return (
    <>
      <Flex direction="row" w="full">
        <Flex w="40%" justifyContent="center" alignItems="center">
          <Flex h="588px" w="532px" position="relative">
            <Image
              src={s3ImageURL(activeStep.imageUrl)}
              fill
              alt=""
              priority
              quality={100}
              style={{ objectFit: 'contain' }}
            />
          </Flex>
        </Flex>
        <Flex w="60%" h="588px" direction="column" justifyContent="center" alignItems="center">
          <Heading size="lg" pb={14}>
            Comment ça marche ?
          </Heading>
          {steps.map((step, i) => (
            <Flex
              key={`step_${step.number}`}
              direction="row"
              h="auto"
              w="full"
              alignItems="center"
              justifyContent="center"
            >
              <Flex
                h="full"
                borderRight="1px solid rgba(0, 0, 0, 0.1)"
                w="90px"
                justifyContent="center"
                alignItems="center"
                borderBottom={i !== steps.length - 1 ? '1px solid rgba(0, 0, 0, 0.1)' : ''}
              >
                <Heading
                  size="lg"
                  color={activeStep.number === step.number ? 'green' : 'main_grey'}
                  cursor={activeStep.number === step.number ? 'default' : 'pointer'}
                  onClick={() => setActiveStep(step)}
                  p={6}
                >
                  {step.number}
                </Heading>
              </Flex>
              <Flex
                h="full"
                justifyContent="center"
                alignItems="center"
                p={6}
                borderBottom={i !== steps.length - 1 ? '1px solid rgba(0, 0, 0, 0.1)' : ''}
                color={activeStep.number === step.number ? 'green' : 'main_grey'}
              >
                {step.text}
              </Flex>
            </Flex>
          ))}
          <Flex direction="row" w="full" justifyContent="center">
            <Box w="90px" />
            <Flex direction="column" maxW="500px" color="main_grey">
              <Text size="sm" fontStyle="italic">
                *4 projets lauréats seront sélectionnés
              </Text>
              <Text size="sm" fontStyle="italic">
                **programme court : portraits des lauréats avec un état des lieux du projet et des principaux enjeux. 2
                épisodes d’une minute par lauréat seront diffusés sur M6 dès la rentrée 2023
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Flex w="full" direction="row" justifyContent="center" columnGap={4} mt={10}>
        <Icon
          name="ChevronLeftFillCircle"
          color={activeStep.number > 1 ? 'green' : 'main_grey'}
          onClick={(e) => (activeStep.number > 1 ? setActiveStep(steps[activeStep.number - 2]) : e.preventDefault())}
          cursor={activeStep.number > 1 ? 'pointer' : 'not-allowed'}
        />
        <Icon
          name="ChevronRightFillCircle"
          color={activeStep.number < steps.length ? 'green' : 'main_grey'}
          onClick={(e) =>
            activeStep.number < steps.length ? setActiveStep(steps[activeStep.number]) : e.preventDefault()
          }
          cursor={activeStep.number < steps.length ? 'pointer' : 'not-allowed'}
        />
      </Flex>
    </>
  )
}

export default HowItWorks
