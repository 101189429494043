import { Flex, Heading, Text } from '@chakra-ui/react'
import { ReactElement } from 'react'

type Props = {
  steps: { number: number; imageUrl: string; text: ReactElement }[]
}

const HowItWorksMobile = ({ steps }: Props) => {
  return (
    <Flex direction="column" w="full">
      <Heading size="lg" pb={4} textAlign="center">
        Comment ça marche ?
      </Heading>
      {steps.map((step) => (
        <Flex
          key={`step_mobile_${step.number}`}
          direction="row"
          justifyContent="center"
          alignItems="center"
          columnGap={4}
        >
          <Heading size="lg" color="green">
            {step.number}
          </Heading>
          <Flex h="full" justifyContent="center" alignItems="center" p={6} color="green">
            {step.text}
          </Flex>
        </Flex>
      ))}
      <Flex direction="row" w="full" justifyContent="center">
        <Flex direction="column" maxW="500px" color="main_grey" ml={12}>
          <Text size="sm" fontStyle="italic">
            *4 projets lauréats seront sélectionnés
          </Text>
          <Text size="sm" fontStyle="italic">
            **programme court : portraits des lauréats avec un état des lieux du projet et des principaux enjeux. 2
            épisodes d’une minute par lauréat seront diffusés sur M6 dès la rentrée 2023
          </Text>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default HowItWorksMobile
